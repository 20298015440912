<template>
  <v-container fluid>
    <CRUD
      ref="crud"
      id-method="params"
      :fields="fields"
      :table="table"
      :api="api"
      @form:load="initForm"
    >
      <template v-slot:[`form.input.IONo`]="{ item }">
        <v-autocomplete
          item-text="IONo"
          item-value="IONo"
          :value="item.value"
          :loading="ioNoData.loading"
          :items="ioNoData.items.length > 0 ? ioNoData.items : item.value ? [item.value] : []"
          :search-input.sync="ioNoData.search"
          hide-no-data
          :label="item.label + '*'"
          hide-details="auto"
          dense
          @change="onIOChange($event, item)"
        ></v-autocomplete>
      </template>
    </CRUD>
  </v-container>
</template>

<script>
import axios from "axios";
import CRUD from "@/components/util/CRUD/CRUD";

export default {
  components: {
    CRUD
  },
  data() {
    return {
      api: {
        url: "/ccms/table/",
        params: {
          tableName: "cnetd.project"
        }
      },
      table: {
        text: "Project",
        key: "ProjectID",
        default: {
          itemsPerPage: 20,
          sortBy: ["UpdateDate"],
          sortDesc: [true]
        },
        components: {
          filter: true
        }
      },
      fields: [
        {
          text: "IO No",
          name: "IONo",
          searchable: true,
          input: {
            validation: ["mandatory"]
          }
        },
        {
          text: "Campaign Name (IO)",
          name: "IONocampaign_name",
          searchable: true,
          input: {
            disabled: true
          }
        },
        {
          text: "Project Name",
          name: "ProjectNM",
          searchable: true,
          input: {
            validation: ["mandatory"]
          }
        },
        {
          text: "Sales Manager",
          name: "BDMID",
          searchable: true,
          input: {
            type: "L",
            validation: ["mandatory"],
            if: { update: "disabled" }
          },
          foreign: {
            table: "cnetd.staffmst",
            key: "StaffID",
            display: "StaffNM",
            where: `StaffTypeID = 1 AND AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "PM",
          name: "PMID",
          searchable: true,
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.staffmst",
            key: "StaffID",
            display: "StaffNM",
            where: `StaffTypeID = 4 AND AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "Client",
          name: "ClientID",
          searchable: true,
          input: {
            type: "L",
            validation: ["mandatory"],
            if: { update: "disabled" }
          },
          foreign: {
            table: "cnetd.clientmst",
            key: "ClientID",
            display: "ClientNM",
            where: `AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "Start Date",
          name: "PStartDate",
          searchable: true,
          input: {
            type: "D"
          }
        },
        {
          text: "End Date",
          name: "PEndDate",
          searchable: true,
          input: {
            type: "D"
          }
        },
        {
          text: "Start Time",
          name: "PStartTime",
          input: {
            type: "D",
            control: "Time",
            value: this.$store.state.auth.user.areaid == "US" ? "08:30" : null
          }
        },
        {
          text: "End Time",
          name: "PEndTime",
          input: {
            type: "D",
            control: "Time",
            value: this.$store.state.auth.user.areaid == "US" ? "17:00" : null
          }
        },
        {
          text: "Status",
          name: "Status",
          searchable: true,
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.projectstatus",
            key: "Status_Value",
            display: "Status_Text"
          }
        },
        {
          text: "Project Type",
          name: "ProjectType",
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.projecttype",
            key: "KeyName",
            display: "KeyValue"
          }
        },
        {
          text: "Project Amount",
          name: "IO_Amount",
          listable: false,
          input: {
            validation: ["mandatory"]
          }
        },
        {
          text: "Target",
          name: "Target",
          listable: false,
          input: {
            validation: ["mandatory"]
          }
        },
        {
          text: "Cost Budget (%)",
          name: "Cost",
          listable: false,
          input: {
            value: "20.00"
          }
        },
        {
          text: "EDM Leads",
          name: "EDM_leads",
          listable: false
        },
        {
          text: "Average Daily TM Cost",
          name: "Ave_Daily_Cost",
          input: {
            value: ""
          }
        },
        {
          text: "LineOrderNumber",
          name: "LineOrderNumber",
          input: {
            disabled: true,
            if: { add: "hidden" }
          }
        },
        {
          text: "Comments",
          name: "Comments",
          input: {
            cols: 12,
            control: "textarea"
          }
        }
      ],
      ioNoData: {
        val: null,
        items: [],
        search: "",
        loading: false
      },
      source: undefined
    };
  },
  watch: {
    "ioNoData.search"(val) {
      val && val !== this.ioNoData.val && this.searchIo(val);
    }
  },
  methods: {
    initForm(e) {
      if (!e.Ave_Daily_Cost) {
        this.$axios.get("/ccms/getDailyAmt").then((res) => {
          if (res.data) {
            this.$refs.crud.updateValue("Ave_Daily_Cost", res.data);
          }
        });
      }
    },
    onIOChange(value) {
      let selectedItem = this.ioNoData.items.find((e) => e.IONo == value);
      for (const [key, value] of Object.entries(selectedItem)) {
        this.$refs.crud.updateValue(key, value);
      }
    },
    searchIo(val) {
      this.source && this.source.cancel("Operation canceled due to new request.");
      this.source = axios.CancelToken.source();

      this.ioNoData.loading = true;
      this.$axios
        .get("/ccms/searchIO", {
          params: {
            ioNo: val
          },
          cancelToken: this.source.token
        })
        .then((res) => {
          if (res.data) {
            this.ioNoData.items = res.data;
          }
        })
        .finally(() => {
          this.ioNoData.loading = false;
        });
    }
  }
};
</script>
